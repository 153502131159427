import React from "react"

import { api_signup } from '../api/Api';
import { Link } from 'react-router-dom';

import { GlobalContext } from "../App";

export function Register() {

	const { isMobile, setEmailConfirm } = React.useContext(GlobalContext);

	const [formData, setFormData] = React.useState({
		name: "",
		email: "",
		blr: false,
		rus: false,
		password: ""
	})

	const [formStatus, setFormStatus] = React.useState("");

	const [fieldErrors, setFieldErrors] = React.useState([]);

	const [registerClicked, setRegisterClicked] = React.useState(false);

	React.useEffect(() => {
		setRegisterClicked(false);
	}, [formStatus]);

	function handleChange(event) {
		const { name, type, value, checked } = event.target
		setFormStatus("");
		if(fieldErrors.includes(name)) {
			setFieldErrors(fieldErrors.filter(item => item !== name));
		}
		if(name === "blr" || name === "rus") {
			if(fieldErrors.includes("language")) {
				setFieldErrors(fieldErrors.filter(item => item !== "language"));
			}
		}
		setFormData(prevData => {
			return {
				...prevData,
				[name]: type === "checkbox" ? checked : value
			}
		})
	}

	function handleSubmit(event) {
		setRegisterClicked(true);
		event.preventDefault();		//Don't re-render

		var lang = (formData.blr ? "B" : "") + (formData.rus ? "R" : "");

		const submitData = {
			name: formData.name,
			email: formData.email,
			language: lang,
			password: formData.password
		}

		api_signup(submitData, setFieldErrors, setFormStatus, setEmailConfirm);
	}

	return (

		<div className="form-page">
			<div className="contact">
				<div className="contact-inner">
					<div className={isMobile ? "contact-grid-narrow" : "contact-grid"}>
						<div className="contact-left">
							<h1>
								Register
							</h1>
							<p>
								Use the form here to register and get instant access to PDF lesson resources.
							</p>
							<p>
								
							</p>
							<p>
								Already signed up?
								<Link to="/login" className="button-highlighted">Click here to login</Link>
							</p>
							<p>
								Forgot password?
								<Link to="/passwordresetrequest" className="button-highlighted">Reset Password</Link>
							</p>
							<p className="formStatus">{formStatus}</p>
						</div>
						<div className="contact-right">
	
							<form className="contact-card" id="contactform" action="/contact" method="post" onSubmit={handleSubmit}>
								<h1>Register here</h1>
								<input type="hidden" name="_csrf" value="9d74fb3f-7673-49ad-b29c-93d00d8ee548" />
								
								<label htmlFor="name">Your Name:</label> 
								<input name="name" placeholder="Name" type="text" id="name" onChange={handleChange} value={formData.name}
								className={fieldErrors.includes("name")
									? "form-field-error"
									: "form-field"} 
								/>
							
								<label htmlFor="email">Your Email:</label>
								<input name="email" placeholder="Email" type="email" id="email" onChange={handleChange} value={formData.email} 
									className={fieldErrors.includes("email")
										? "form-field-error"
										: "form-field"} 
								/>
								<label htmlFor="password">Password:</label>
								<input name="password" placeholder="Password" type="password" id="password" onChange={handleChange} value={formData.password}
									className={fieldErrors.includes("password")
											? "form-field-error"
											: "form-field"}
								/>
	
								<p>Which language would you like to learn?</p>
								<div className={fieldErrors.includes("language")
											? "form-cb-error"
											: "form-cb"}>
									<input type="checkbox" id="blr" name="blr" checked={formData.blr} onChange={handleChange} /><label htmlFor="blr">Belarusian</label>
								</div>
								<div className={fieldErrors.includes("language")
											? "form-cb-error"
											: "form-cb"}>
									<input type="checkbox" id="rus" name="rus" checked={formData.rus} onChange={handleChange} /><label htmlFor="rus">Russian</label>
								</div>
								<div className={fieldErrors.length === 0 && !registerClicked ? "formButton" : "formButton-disabled"} id="formButton">
									<button>Register now &#10095;</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}