

import React from "react"
import { HeaderBlock } from '../panels/HeaderBlock';
import { MainBlock } from '../panels/MainBlock';
import { AboutMe } from '../panels/AboutMe';
import { Testimonials } from '../panels/Testimonials';
import { Contact } from '../panels/Contact';
import { GlobalContext } from "../App";

export function Homepage() {
	
	const { setEmailConfirm } = React.useContext(GlobalContext);
	
	React.useEffect(() => {
		setEmailConfirm(false);
	}, [setEmailConfirm]);

	return (
		<>
			<HeaderBlock />
			<div id="lessons" className="anchor-offset" />
			<MainBlock />
			<div id="about" className="anchor-offset" />
			<AboutMe />
			<Testimonials />
			<div id="contact" className="anchor-offset" />
			<Contact />
		</>
	)
}