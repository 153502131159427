import React from "react"

import { api_resetpassword_request } from '../api/Api';
import { Link } from 'react-router-dom';

import { GlobalContext } from "../App";


export function PasswordResetReq() {

	const { setEmailConfirm, isMobile } = React.useContext(GlobalContext);

	const [formData, setFormData] = React.useState({
		email: ""
	})

	const [formStatus, setFormStatus] = React.useState("");

	const [fieldErrors, setFieldErrors] = React.useState([]);

	const [buttonClicked, setButtonClicked] = React.useState(false);

	React.useEffect(() => {
		setButtonClicked(false);
	}, [formStatus]);
	

	function handleChange(event) {
		const { name, value } = event.target
		setButtonClicked(false);
		setFormStatus("");
		if(fieldErrors.includes(name)) {
			setFieldErrors(fieldErrors.filter(item => item !== name));
		}
		setFormData(prevData => {
			return {
				...prevData,
				[name]: value
			}
		})
	}

	function handleSubmit(event) {
		setButtonClicked(true);
		event.preventDefault()		//Don't re-render

		const submitData = {
			email: formData.email
		}

		api_resetpassword_request(submitData, setFieldErrors, setFormStatus, setEmailConfirm);
	}

	return (

		<div className="form-page">
			<div className="contact">
				<div className="contact-inner">
					<div className={isMobile ? "contact-grid-narrow" : "contact-grid"}>
						<div className="contact-left">
							<h1>
								Password reset.
							</h1>
							<p>
								Enter your email here and I will send you an email link so you can change your password.
							</p>
							<p>
								
							</p>
							<p>
								Remember your password?
								<Link to="/login" className="button-highlighted">Login</Link>
							</p>
							<p>
								Not yet registered?
								<Link to="/register" className="button-highlighted">Register</Link>
							</p>
							<p className="formStatus">{formStatus}</p>
						</div>
						<div className="contact-right">
	
							<form className="contact-card" id="contactform" action="/contact" method="post" onSubmit={handleSubmit}>
								<h1>Password reset request</h1>
								<input type="hidden" name="_csrf" value="9d74fb3f-7673-49ad-b29c-93d00d8ee548" />
								
								<label htmlFor="email">Your Email:</label>
								<input name="email" placeholder="Email" type="email" id="email" onChange={handleChange} value={formData.email} 
									className={fieldErrors.includes("email")
										? "form-field-error"
										: "form-field"} 
								/>
								<div className={fieldErrors.length === 0 && !buttonClicked ? "formButton" : "formButton-disabled"} id="formButton">
									<button>Send &#10095;</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}