import React from "react"
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import './App.scss';
import { TopMenu } from './panels/TopMenu';
import { Footer } from './panels/Footer';
import { Homepage } from './pages/Homepage';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { PasswordResetReq } from './pages/PasswordResetReq';
import { PasswordReset } from './pages/PasswordReset';
import { MemberArea } from './pages/MemberArea';
import { CheckEmail } from './pages/CheckEmail';
import { ConfirmSignup } from './pages/ConfirmSignup';

import { getCookie } from './api/Api';

/************ Context object ************/
export const GlobalContext = React.createContext();
	
function App() {

	/********* Current logged in user ***************/
	const [user, setUser] = React.useState();

	function isLoggedIn() {
		return user !== undefined;
	}

	React.useEffect(() => {
		getCookie(setUser);
	}, []);

	/********* Scroll position ***************/
	const [scrollPosition, setScrollPosition] = React.useState(0);
	
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	/********* Window size ***************/
	const [isMobile, setIsMobile] = React.useState(false);

	//choose the screen size 
	const handleResize = () => {
		if (window.innerWidth < 1020) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
	}, []);

	/*********** Flag indicating the user is required to respond to an email *************/
	const [emailConfirm, setEmailConfirm] = React.useState(false);

	return (
					
		<div className="App">

			<Router>			{/* Router is at this level because there are also routes in topmenu.*/}
				<GlobalContext.Provider value={{user, setUser, isMobile, emailConfirm, setEmailConfirm, scrollPosition}}>
					<div id="top" className="anchor-offset" />
					<TopMenu />
					<div className="content-outer">
						
						<Routes>
							<Route path="/login" element={
								<>
								    { isLoggedIn() ? <Navigate to="/" /> : <Login /> }
								</>
							} />
							<Route path="/register" element={
								<>
								    { isLoggedIn() ? <Navigate to="/" /> : 
								    	emailConfirm 
								    		? <CheckEmail />
								    		: <Register /> }
								</>
							} />
							<Route path="/confirmsignup" element={
								<>
								    { isLoggedIn() ? <Navigate to="/" /> : <ConfirmSignup /> }
								</>
								
							} />
							<Route path="/passwordresetrequest" element={
								<>
								    { isLoggedIn() ? <Navigate to="/" /> : 
								    	emailConfirm 
								    		? <CheckEmail />
								    		: <PasswordResetReq /> }
								</>
							} />
							<Route path="/passwordreset" element={
								<>
									{ isLoggedIn() ? <Navigate to="/" /> : <PasswordReset /> }
								</>
							} />
							<Route path="/*" element={
								<>
									{ isLoggedIn() ? 
										<MemberArea /> :
										<Homepage /> }
								</>
							} />
		
						</Routes>
					
						<Footer />	
					</div>
				</GlobalContext.Provider>
			</Router>												
		</div>
		
				
	);
}

export default App;

