import React from "react"

import { GlobalContext } from "../App";

export function CheckEmail() {

	const { emailConfirm } = React.useContext(GlobalContext);

	return (

		<div className="form-page">
			<div className="contact">
				<div className="contact-inner">
					<h1>
						Thank you
					</h1>
					<p>
						An email has been sent to {emailConfirm}
					</p>
					<p>
						Please check your email to continue.
					</p>

				</div>
			</div>
		</div>

	)
}