import React from "react"
import { Link } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";

import { GlobalContext } from "../App";

import { api_logout } from '../api/Api';

export function TopMenu() {

	const { scrollPosition, user, setUser } = React.useContext(GlobalContext);

	function logout() {
		api_logout(setUser);
	}

	return (
		<header>
			<nav className={scrollPosition === 0 ? "navbar" : "navbar-scrolled"}>
				<div className="navbar-inner">
				
					<Routes>
						<Route path="/" element={
							<ul className="navbar-buttons">
								<li><a title="Home" href="#top">Home</a></li>
								{ user === undefined && <li><a title="Lessons" href="#lessons">Private Lessons</a></li> }
								{ user === undefined && <li><a title="About" href="#about">About</a></li> }
								{ user === undefined && <li><a title="Contact" href="#contact">Contact</a></li> }			
								{ user === undefined && <li><Link to="/register">Register</Link></li> }		 
								{ user === undefined && <li><Link to="/login">Login</Link></li> }
								{ user !== undefined && <li><Link to="/" onClick={logout}>Logout</Link></li> }
							</ul>			 
						}/>
						<Route path="/login" element={
							<ul className="navbar-buttons">
								 <li><Link to="/">Home</Link></li>
								 { user === undefined && <li><Link to="/register">Register</Link></li> }		 
							</ul>
						}/>
						<Route path="/register" element={
							<ul className="navbar-buttons">
								 <li><Link to="/">Home</Link></li>
								 { user === undefined && <li><Link to="/login">Login</Link></li> }
							</ul>
						} />
						<Route path="/resetpassword" element={
							<ul className="navbar-buttons">
								 <li><Link to="/">Home</Link></li>
								 { user === undefined && <li><Link to="/register">Register</Link></li> }		 
								 { user === undefined && <li><Link to="/login">Login</Link></li> }
							</ul>
						} />
						<Route path="/*" element={
							<ul className="navbar-buttons">
								 <li><Link to="/">Home</Link></li>
							</ul>
						} />						
					</Routes>
				
				</div>
			</nav>
		</header>

	)
}

