
const API_BASE = process.env.REACT_APP_API_BASE;

export function api_submitContactForm(formData, setFormStatus, setFormSubmitted) {

	setFormStatus({
		message: ""
	})

	if(!validateEmail(formData.email)) {
		alert("Please enter a valid email address.");
		setFormSubmitted(false);
		return;
	}

	fetch(API_BASE + "contact", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			alert("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data) {
			setFormStatus({
				message: data.message
			})
			setFormSubmitted(true);
		}

	});

}

function validateEmail(mail) 
{
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  	{
    	return (true)
  	}
    return (false)
}

export function api_signup(formData, setFieldErrors, setFormStatus, setEmailConfirm) {

	if(formData.password.length < 6) {
		setFormStatus("Please enter a longer password.");
		setFieldErrors([ "password" ]);
		return;
	}

	fetch(API_BASE + "user/signup", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data.fieldErrors !== undefined) {
			var fieldErrors = Object.keys(data.fieldErrors);
			setFieldErrors(fieldErrors);
		}
		if(data.message) {
			setFormStatus(data.message);
		} else {
			setEmailConfirm(formData.email);
		}

	});
}


export function api_resetpassword_request(formData, setFieldErrors, setFormStatus, setEmailConfirm) {

	fetch(API_BASE + "user/passwordresetrequest", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data.fieldErrors !== undefined) {
			var fieldErrors = Object.keys(data.fieldErrors);
			setFieldErrors(fieldErrors);
		}
		if(data.message) {
			setFormStatus(data.message);
		} else {
			setEmailConfirm(formData.email);
		}

	});

}

export function api_passwordReset(formData, setFieldErrors, setFormStatus, setUser) {
	
	if(formData.password.length < 6) {
		setFormStatus("Please enter a longer password.");
		setFieldErrors([ "password" ]);
		return;
	}
	
	fetch(API_BASE + "user/passwordreset", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data.message) {
			setFormStatus(data.message);
		} else {
			setUser(data);
		}
	});
}

export function api_confirmSignup(formData, setFormStatus, setUser) {
	
	fetch(API_BASE + "user/confirmsignup", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data.message) {
			setFormStatus(data.message);
		} else {
			setUser(data);
		}
	});
}

export function api_login(formData, setFormStatus, setUser, rememberMe) {

	fetch(API_BASE + "user/authenticate", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	}).then((response) => {
		return response.json()
	}, (error) => {
		if (error) {
			setFormStatus("Problem with server, please try again later.");
		}
	}).then(data => {
		if(data.message) {
			setFormStatus(data.message);
		} else {
			setUser(data);
			if(rememberMe) {
				setCookie(data); 
			}
		}

	});
}

const COOKIE_KEY = "katyakirkwood.com";

function setCookie(value) {
	const now = new Date();
	const item = {
		value: value,
		expiry: now.getTime() + 1000*60*60*24*365	// 1 Year
	};
	localStorage.setItem(COOKIE_KEY, JSON.stringify(item));
}

export function getCookie(setUser) {
	const itemStr = localStorage.getItem(COOKIE_KEY);
	if (!itemStr) {
		return;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		// Session cookie has expired.
		localStorage.removeItem(COOKIE_KEY);
		setUser(undefined);
		return;
	}
	if(item) {
		api_loginWithCookie(item.value.email, item.value.jwt, setUser);
	}
}

export function api_logout(setUser) {
	localStorage.removeItem(COOKIE_KEY);
	setUser(undefined);
}

export function api_loginWithCookie(email, jwt, setUser) {
	
	fetch(API_BASE + "user/authenticatewithtoken", {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'html/text',
			Authorization: 'Bearer ' + jwt
		},
		body: email
	}).then((response) => {
		return response.json();
	}, (error) => {
		if (error) {
			// Ignore user will just be returned to non-logged in page
		}
	}).then(data => {
		if(data.message) {
			// Ignore user will just be returned to non-logged in page
		} else {
			setUser(data);
			setCookie(data); 
		}

	});
}

/************************* Members area resource files ***********************/

export function api_getResources(setFormStatus, setResources) {

	var reqString = API_BASE + "resources";

	fetch(reqString, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
		}

	}).then((response) => {
		if (response.ok) {
			if (response.json.length === 0) {
				setFormStatus({ message: "There are no resources to show." });
			}
			response.json().then(data => (setResources(data)))
		} else {
			setFormStatus({ message: "Problem with server response" })
		}
	}, (error) => {
		if (error) {
			setFormStatus({ message: "Problem with server" })
		}
	})
}


export function api_getResource(fileName) {
	var reqString = API_BASE + "resource/" + fileName;
	
	fetch(reqString, {
		method: 'GET'
	}).then((response) => {
      		response.blob().then(blob => {
        		let url = window.URL.createObjectURL(blob);
        		let a = document.createElement('a');
        		a.href = url;
        		a.download = fileName;
        		a.click();
    		});
	}, (error) => {
		if (error) {
		}
	})
	
}
