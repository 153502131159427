import React from "react"
import { GlobalContext } from "../App";
import { api_getResources } from '../api/Api';
import { api_getResource } from '../api/Api';
import { Link } from 'react-router-dom';

export function MemberPanel() {

	const { user, isMobile } = React.useContext(GlobalContext);

	const language = user.languages === "BR" ? "Belarusian and Russian" :
					 user.languages === "B" ? "Belarusian" : "Russian";

	const [formStatus, setFormStatus] = React.useState();
	const [resources, setResources] = React.useState([]);

	const [resourceListRender, setResourceListRender] = React.useState();


	React.useEffect(() => {
		api_getResources(setFormStatus, setResources);
	}, []);
	
	React.useEffect(() => {
		var resourceRender = "";

		if (resources != null) {
			if (resources !== undefined && resources.length === 0) {
				resourceRender = (
					<div className="nomatches">
						<br />
						{formStatus ? formStatus.message : ""}
						<br />
					</div>
				)
			} else {
				resourceRender = resources && resources.map(resource => (
					<Link key={resource} to="." 
						onClick={() => api_getResource(resource)}
						>{resource}</Link>
				))
			}
		} else {
			resourceRender = (
				<div className="nomatches">
					<br />
						{formStatus ? formStatus.message : ""}
					<br />
				</div>
			)
		}
		setResourceListRender(resourceRender)

	}, [resources, formStatus]);

	

	return (
		<div className="content-inner main-block">
			<div className={isMobile ? "wide" : "narrow"}>
				<h1>
					Hi {user.name}
				</h1>
				<h2>
					Welcome to my members area
				</h2>
				<p>
					Here are some resources to help you with your {language} studies.
				</p>
				{resourceListRender}
			</div>
		</div>

	)
}