import React from "react"
import { HeaderBlock } from '../panels/HeaderBlock';
import { MemberPanel } from '../panels/MemberPanel';

export function MemberArea() {

	return (
		<>
			<HeaderBlock />
			<MemberPanel />
		</>

	)
}