
import React from "react"
import { api_passwordReset } from '../api/Api';

import { GlobalContext } from "../App";


export function PasswordReset() {

	const { setUser, isMobile } = React.useContext(GlobalContext);

	const [formStatus, setFormStatus] = React.useState("");

	const [fieldErrors, setFieldErrors] = React.useState([]);

	const [loginClicked, setLoginClicked] = React.useState(false);

	const params = new URLSearchParams(window.location.search);
	
	const [formData, setFormData] = React.useState({
		email: params.get('email'),
		token: params.get('token'),
		password: ""
	})

	React.useEffect(() => {
		setLoginClicked(false);
	}, [formStatus]);

	function handleChange(event) {
		const { name, type, value, checked } = event.target
		setFormStatus("");
		if(fieldErrors.includes(name)) {
			setFieldErrors(fieldErrors.filter(item => item !== name));
		}
		setFormData(prevData => {
			return {
				...prevData,
				[name]: type === "checkbox" ? checked : value
			}
		})
	}

	function handleSubmit(event) {
		setLoginClicked(true);
		event.preventDefault()		//Don't re-render
		api_passwordReset(formData, setFieldErrors, setFormStatus, setUser);
	}

	return (

		<div className="form-page">
			<div className="contact">
				<div className="contact-inner">
					<div className={isMobile ? "contact-grid-narrow" : "contact-grid"}>
						<div className="contact-left">
							<h1>
								Password reset
							</h1>
							<p>
								Enter a new password in the form on the right and we'll log you straight in.
							</p>

							<p className="formStatus">{formStatus}</p>
						</div>
						<div className="contact-right">
	
							<form className="contact-card" id="contactform" action="/contact" method="post" onSubmit={handleSubmit}>
								<h1>Password Reset</h1>
								<input type="hidden" name="_csrf" value="9d74fb3f-7673-49ad-b29c-93d00d8ee548" />
							
								<label htmlFor="password">New Password:</label>
								<input name="password" placeholder="Password" type="password" id="password" onChange={handleChange} value={formData.password}
									className={fieldErrors.includes("password")
											? "form-field-error"
											: "form-field"}
								/>
	
								<div className={fieldErrors.length === 0 && !loginClicked ? "formButton" : "formButton-disabled"} id="formButton">
									<button>Reset my password &#10095;</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}