import React from "react"
import { api_submitContactForm } from '../api/Api';
import { GlobalContext } from "../App";

export function Contact() {

	const { isMobile } = React.useContext(GlobalContext);
	
	const [formData, setFormData] = React.useState({
		name: "",
		email: "",
		blr: false,
		rus: false,
		message: ""
	})
	
	const [formSubmitted, setFormSubmitted] = React.useState(false);

	const [formStatus, setFormStatus] = React.useState();

	function handleChange(event) {
		const { name, type, value, checked } = event.target
		setFormSubmitted(false);
		setFormData(prevData => {
			return {
				...prevData,
				[name]: type === "checkbox" ? checked : value
			}
		})
	}

	function handleSubmit(event) {
		event.preventDefault()		//Don't re-render
		
		var lang = (formData.blr ? "B" : "") + (formData.rus ? "R" : "");
		
		const submitData = {
			name: formData.name,
			email: formData.email,
			lang: lang,
			message: formData.message
		}
		
		api_submitContactForm(submitData, setFormStatus, setFormSubmitted);
	}

	return (
		<div className="contact">
			<div className="contact-inner">
				<div className={isMobile ? "contact-grid-narrow" : "contact-grid"}>
					<div className="contact-left">
						<h1>
							Get in Touch
						</h1>
						<p>
							Use the form here to contact me to arrange a lesson or ask any questions.
						</p>
						<p>
							I always welcome new students and would love for you to have an introductory lesson with me, so please do get in touch. I look forward to meeting you.
						</p>
					</div>
					<div className="contact-right">

						<form className="contact-card" id="contactform" action="/contact" method="post" onSubmit={handleSubmit}>
							<h1>Send me a message</h1>
							<input type="hidden" name="_csrf" value="9d74fb3f-7673-49ad-b29c-93d00d8ee548"/>
							<p>Get in touch and I will get back to you soon.</p>
							<label htmlFor="name">Your Name:</label><input name="name" placeholder="Name" type="text" id="name" onChange={handleChange} value={formData.name} />
							<label htmlFor="email">Your Email:</label><input name="email" placeholder="Email" type="email" id="email" onChange={handleChange} value={formData.email} />
							<p>Which language would you like to learn?</p>
							<div>
								<input type="checkbox" id="blr" name="blr" checked={formData.blr} onChange={handleChange}/><label htmlFor="blr">Belarusian</label>
							</div>
							<div>
								<input type="checkbox" id="rus" name="rus" checked={formData.rus} onChange={handleChange}/><label htmlFor="rus">Russian</label>
							</div>
							<p>Message (optional):</p>
							<textarea name="message" type="text" id="message" onChange={handleChange} value={formData.message} />
								<div className="formButton" id="formButton">
								{ formSubmitted ?
									<p>{formStatus.message}</p>
									:
										<button>Send message &#10095;</button>
								}
							</div>
						</form>
					</div>
				</div>				
			</div>
		</div>

	)
}