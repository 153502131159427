import React from "react"

import { GlobalContext } from "../App";

export function MainBlock() {

	const { isMobile } = React.useContext(GlobalContext);

	return (
		<div className="content-inner main-block">
			<div className={isMobile ? "wide" : "narrow"}>
				<h1>
					Belarusian and Russian language lessons
				</h1>
				<h2>
					Online 1-on-1 language lessons in English
				</h2>
				<p>
					I am a language teacher, fluent in Belarusian and Russian. My lessons are delivered via Skype and are tailored to the individual. I welcome students who are absolute beginners who would like to learn some tourist phrases, all the way up to advanced grammar students who are studying for business or exam purposes.
				</p>
				<p>
					I take pride in my lessons and I always make sure that I understand the goals of my students. I have some innovative lesson ideas that I would love to share with you.
				</p>
				<p>
					My individually tailored approach is the reason why I have many loyal students that are taking their language skills to a new level.
				</p>
			</div>
			<div className="values-grid">
				<div className="values-item">
					<div className="icon" style={{color: '#e91e63'}}>
						<i className="fab fa-weixin"></i>
					</div>
					<h1>
						Responsive
					</h1>
					<p>
						Lessons can be arranged at a time to suit you and are delivered via Skype. Lessons are tailored to suit the individual.
					</p>
				</div>
				<div className="values-item">
					<div className="icon" style={{color: '#00bcd4'}}>
						<i className="fas fa-check"></i>
					</div>
					<h1>
						Quality
					</h1>
					<p>
						I am a native speaker of both Belarusian and Russian. I have experience of teaching all levels, from complete beginners to advanced levels.
					</p>
				</div>
				<div className="values-item">
					<div className="icon" style={{color: '#4caf50'}}>
						<i className="fas fa-dollar-sign"></i>
					</div>
					<h1>
						Value
					</h1>
					<p>
						Competitive pricing to suit your budget. Single lessons, or block bookings available on request.
					</p>
				</div>
			</div>
		</div>

	)
}