import React from "react"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export function Testimonials() {

	const spinner_items = [
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Katia is always super patient, encouraging and able to answer whatever question I have about Russian grammar.</p>
				<small>Jacqueline Sawyer, 64 Russian lessons, Sep 12, 2021</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Katia is a fantastic teacher because she is always focused on improving my language skills and also very dedicated. She is professional, highly effective and respectable. She continually assesses me and gives me constructive feedback. I strongly reccomend her!</p>
				<small>Michaela, 82 Russian lessons, Jan 18, 2021</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Katia is a wonderful teacher! She puts a lot of thought into preparing personalized and interesting lessons.</p>
				<small>Alana, 31 Belarusian lessons, May 27, 2020</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Katia is hands down the best teacher ever, really. She is always very well prepared and when she notices I have an issue with something, eg some grammar points, she will take time to explain it to me or send me material that will help me fix the issue. I also really enjoy that when we watch a movie, she explains to me little jokes or quirks that I would not get otherwise, and she always makes sure to study material that will interest me. I am always looking forward to the next lesson with Katia. Thank you!</p>
				<small>Adriana, 16 Russian lessons, Aug 18, 2020</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Always on point...lessons always structured to where my needs are!</p>
				<small>Alston, 63 Russian lessons, Sep 12, 2021</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Thanks to Katia, my Russian skills have been improving. I recommend working with Katia because she’s a professional and skilled teacher who can quickly identify a student’s weaknesses and target them with useful exercises and practice. Also, Katia is flexible and adapts to the needs and wants of students. Overall, Katia’s impressive skill set makes her a great resource for learning your target language.</p>
				<small>Wyland, 10 Russian lessons, Apr 13, 2020</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Super progress made in Belarusian already!</p>
				<small>Conor Tsar Experience, 104 Belarusian lessons, Oct 5, 2020</small>
			</div>
		</div>,
		<div className="testimonial">
			<div className="testimonial-inner">
				<p>Katya is a great listener and she always has a useful exercise ready for me.</p>
				<small>Robert, 48 Belarusian lessons, Dec 5, 2020</small>
			</div>
		</div>,
	];
		
	const responsive = {
  		0: { items: 1 }
	};
	
	return (
		<div className="testimonials">
			<div className="testimonials-inner">
				<AliceCarousel
				       	autoPlay
		        		autoPlayStrategy="none"
		        		autoPlayInterval={8000}
		        		animationDuration={400}
		        		infinite
	        			disableButtonsControls
	        			touchTracking={false}
		        		responsive={responsive}
		        		items={spinner_items} />
			</div>		
		</div>

	)
}