import React from "react"
import { api_confirmSignup } from '../api/Api';

import { GlobalContext } from "../App";

export function ConfirmSignup() {

	const { setUser } = React.useContext(GlobalContext);

	const [formStatus, setFormStatus] = React.useState("");

	React.useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		api_confirmSignup({
			email: params.get('email'),
			token: params.get('token')
		}, setFormStatus, setUser);
	}, [setUser]);

	return (

		<div className="form-page">
			<div className="contact">
				<div className="contact-inner">
					<h1>
						Thanks for signing up!
					</h1>
					{formStatus ? <p className="formStatus">{formStatus}</p> :
						<p>
							Please wait...
						</p> 
					}
				</div>
			</div>
		</div>

	)
}