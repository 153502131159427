import React from "react"

import { api_login } from '../api/Api';
import { Link } from 'react-router-dom';
import { GlobalContext } from "../App";
import { CookieConsent, getCookieConsentValue } from "react-cookie-consent";

export function Login() {

	const { setUser, isMobile } = React.useContext(GlobalContext);

	const [formData, setFormData] = React.useState({
		email: "",
		password: "",
		rememberMe: false
	})

	const [formStatus, setFormStatus] = React.useState("");

	const [fieldErrors, setFieldErrors] = React.useState([]);

	const [loginClicked, setLoginClicked] = React.useState(false);

	const [rememberMeEnabled, setRememberMeEnabled] = React.useState(false);

	React.useEffect(() => {
		setLoginClicked(false);
	}, [formStatus]);


	function handleChange(event) {
		const { name, type, value, checked } = event.target
		setFormStatus("");
		if(fieldErrors.includes(name)) {
			setFieldErrors(fieldErrors.filter(item => item !== name));
		}
		if(name === "rememberMe" && !rememberMeEnabled) {
			return;
		}
		setFormData(prevData => {
			return {
				...prevData,
				[name]: type === "checkbox" ? checked : value
			}
		})
	}

	function handleSubmit(event) {
		setLoginClicked(true);
		event.preventDefault()		//Don't re-render
		api_login(formData, setFormStatus, setUser, formData.rememberMe);
	}

	React.useEffect(() => {
		console.log(getCookieConsentValue());
		setRememberMeEnabled(getCookieConsentValue() === "true");
	}, []);

	
	function enableCookies() {
		setRememberMeEnabled(true);
	}
	
	return (

		<div className="form-page">
			
			<CookieConsent /*debug={true} enableDeclineButton={true}*/ onAccept={enableCookies}>This website uses cookies to enhance the user experience.</CookieConsent>
			
			<div className="contact">
				<div className="contact-inner">
					<div className={isMobile ? "contact-grid-narrow" : "contact-grid"}>
						<div className="contact-left">
							<h1>
								Login
							</h1>
							<p>
								Login by entering your email and password on the form here.
							</p>
							<p>
								
							</p>
							<p>
								Not yet signed up?
								<Link to="/register" className="button-highlighted">Click here to Register</Link>
							</p>
							<p>
								Forgot password?
								<Link to="/passwordresetrequest" className="button-highlighted">Reset Password</Link>

							</p>
							<p className="formStatus">{formStatus}</p>
						</div>
						<div className="contact-right">
	
							<form className="contact-card" id="contactform" action="/contact" method="post" onSubmit={handleSubmit}>
								<h1>Login here</h1>
								<input type="hidden" name="_csrf" value="9d74fb3f-7673-49ad-b29c-93d00d8ee548" />
							
								<label htmlFor="email">Your Email:</label>
								<input name="email" placeholder="Email" type="email" id="email" onChange={handleChange} value={formData.email} 
									className={fieldErrors.includes("email")
										? "form-field-error"
										: "form-field"} 
								/>
								<label htmlFor="password">Password:</label>
								<input name="password" placeholder="Password" type="password" id="password" onChange={handleChange} value={formData.password}
									className={fieldErrors.includes("password")
											? "form-field-error"
											: "form-field"}
								/>
	
								<div className="form-cb">
									 <input type="checkbox" id="rememberMe" name="rememberMe" checked={formData.rememberMe} onChange={handleChange} />
									 <label htmlFor="rememberMe">Remember me {rememberMeEnabled ? "" : " (accept cookies first)"}</label>
								</div>
								<div className={fieldErrors.length === 0 && !loginClicked ? "formButton" : "formButton-disabled"} id="formButton">
									<button>Login now &#10095;</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}