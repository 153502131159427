import React from "react"

export function HeaderBlock() {

	return (
		<div className="header-block-outer content-inner">
			<div className="header-block-inner">
				<h1>Katya Kirkwood</h1>
				<p>Belarusian and Russian language lessons</p>
			</div>
		</div>
	)
}