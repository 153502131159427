import React from "react"

import { GlobalContext } from "../App";

export function AboutMe() {

	const { isMobile } = React.useContext(GlobalContext);
	
	return (
		<div className="about-me" id="about">
			<div className="about-me-inner">
				<h1>
					About me
				</h1>
				<div className={isMobile ? "about-me-grid-narrow" : "about-me-grid"}>
					<div className="about-me-left">
						<img
					        alt="Katya"
					        src="img/Katya.png"
					        width="500"/>
					</div>
					<div className="about-me-right">
						<h2>
							Katya Kirkwood
						</h2>
						<h3>
							TEACHER
						</h3>
						<p>
							I am Katya. I was born in Belarus, close to Minsk. I am a native speaker of both Belarusian and Russian and I have been teaching both of these languages online for a number of years.
						</p>
						<p>
							I like to learn languages myself and I am proficient in English and German. I have a BA and a BSc and I am currently doing a PhD.
						</p>
						<p>
							I am very interested in ancient civilisations and I have studied classics and Hebrew.
						</p>
					</div>
				</div>				
			</div>
		</div>
	)
}